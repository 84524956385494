import React from "react";
import { Formik } from "formik";
export default function ContactForm({ submitForm }) {
  const [loading, islaoiding] = React.useState(false);
  const [msg, setmsg] = React.useState("");
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmitform = (values) => {
    setmsg("");
    typeof window !== "undefined" && islaoiding(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...values,
      }),
    })
      .then(() => {
        islaoiding(false);
        setmsg("Email Sent!");
      })
      .catch((error) => alert(error));
  };

  return (
    <div className="col-md-8">
      <h3>Contact</h3>
      <Formik
        initialValues={{
          name: "",
          email: "",
          Message: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.name) {
            errors.name = "Required";
          }

          if (!values.Message) {
            errors.Message = "Required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitform(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <div className="row">
              <div className="col-md-6 mb-4">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                />
                {errors.name && touched.name && errors.name}
              </div>
              <div className="col-md-6 mb-4">
                <input
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                />
                {errors.email && touched.email && errors.email}
              </div>
              <div className="col-12 mb-4 mb-xl-5 mb-lg-5 mb-md-5 mb-sm-5">
                <textarea
                  name="Message"
                  className="form-control"
                  rows="3"
                  placeholder="Message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Message}
                ></textarea>
                {errors.Message && touched.Message && errors.Message}
              </div>
              <div className="col-12 mb-4 mb-lg-5 mb-md-5 mb-sm-5">
                <div style={{ paddingBottom: "20px" }}> {!!msg && msg}</div>

                <button type="submit" className="btn btn-primary">
                  {loading ? "Sending..." : " Send Message"}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
