import React from "react";

export default function ContactSidebar() {
  return (
    <div className="col-md-4">
      <div className="contact-detail">
        <h4>To connect with a fertility navigator</h4>
        <a href="mailto:navigators@medanswers.com">navigators@medanswers.com</a>
        <h4>Technical Questions </h4>
        <a href="mailto:tech@medanswers.com">tech@medanswers.com</a>
      </div>
    </div>
  );
}
