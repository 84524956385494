import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import ContactUs from "../../components/contactUs";

export default function ContactUsPage() {
  return (
    <>
      <SEO title="Contact us Page" />

      <ContactUs />
    </>
  );
}
