import React from "react";
import ContactForm from "./contactForm";
import ContactSidebar from "./contactSidebar";
import { Link } from "gatsby";
import "./assets/css/style.scss";
import CrossIcon from "./assets/images/cross.svg";

export default function ContactUs(formData) {
  const submitForm = () => { };
  return (
    <div className="contact-wrap">
      <span className="close-btn">
        <Link to="">
          <img src={CrossIcon} alt="back to home" />
        </Link>
      </span>
      <div className="content">
        <div className="row">
          <ContactForm submitForm={submitForm} />
          <ContactSidebar />
        </div>
      </div>
    </div>
  );
}
